import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Deposition tools`}</h4>
    <p>{`Deposition tools are used to deposit thin films of materials on a silicon wafer. After lithography and etching, these films become different chip layers, including for transistors, interconnects (wires), and other elements.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      